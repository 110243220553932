<template>
    <AutoComplete
        :id="`${rule.id}_location`"
        :name="`${rule.id}_location`"
        :items="locationAutoCompleteItems"
        label="Location"
        placeholder="Search your location"
        :value="selectedLocation"
        :icon-color="iconColor"
        @input="onInput"
    />
</template>

<script>
import AutoComplete from '@/components/forms/AutoComplete';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'AttributeLocation',
    components: {
        AutoComplete,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            locationOptions: [],
            selectedLocation: '',
        };
    },
    computed: {
        iconColor: () => colors['first-primary-color'],
        locationAutoCompleteItems() {
            return this.locationOptions.map((opt) => opt.name);
        },
    },
    async mounted() {
        this.locationOptions = await this.rule.getContent();
        const selected = this.findLocationOption('iso2', this.rule.value);
        if (selected) {
            this.selectedLocation = selected.name;
        }
    },
    methods: {
        onInput(name, locationIndex) {
            const selected = this.locationOptions[locationIndex];
            if (selected) {
                this.$emit('input', selected.iso2);
            }
        },
        findLocationOption(key, value) {
            return this.locationOptions.find((opt) => opt[key] === value);
        },
    },
};
</script>
